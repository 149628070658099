<template>
    <div class="contract-file-card dF fC">
        <div class="f1 relative" >
            <!-- <BhLoading :show="loading"/> -->

            <img :src="file.url" v-if="file.image" id="previewCanvas" ref="canvas" style="width: 100%;height: 100%;object-fit: cover;"/>
            <div class="contract-file-info dF jSB aC px-2">
                <div style="white-space: nowrap;overflow: hidden;text-overflow: ellipsis;">
                    {{file.name}}
                    <span class="text-sm block" v-if="file.pages">({{file.pages}} Pages)</span>
                </div>

                <a-icon type="delete" class="cursor-pointer hover:text-primary" @click="$emit('delete')" />
            </div>
        </div>
    </div>
</template>

<script>
import BhLoading from 'bh-mod/components/common/Loading'
export default {
    props:['file'],
    components:{BhLoading},
    data:() => ({
        loading:true,
    }),
    computed:{
        fileObj(){
            // return this.file && this.file.file || {}
        }
    },
    created(){
        console.log('THIS FILE", th', this.file)
    },
    async mounted(){
        // if (this.fileObj.old){
        //     this.pages = null
        // } else {
        //     // this.pages = await miniPreview(this.file,this.$refs.canvas)
        //     // this.file.pages = this.pages
        //     // this.$emit('updatePages', this.pages)
        // }
        // this.loading = false
        // console.log('THIS REFS', this.$refs.canvas)
    }
}
</script>

<style scoped>
    .contract-file-card{
        background:var(--light-beige);
        width:200px;
        height:300px;
        position: relative;
        padding:10px 10px 0;
    }
    .contract-file-info{
        background:var(--light-beige);
        position: absolute;
        left:0;
        bottom:0;
        width:100%;
        min-height:60px;
    }
    canvas{
        position: absolute;
        left:0;
        top:0;
        width:100%;
        height:100%;
    }
</style>
