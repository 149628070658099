import { render, staticRenderFns } from "./ContractFileCard.vue?vue&type=template&id=254a725c&scoped=true"
import script from "./ContractFileCard.vue?vue&type=script&lang=js"
export * from "./ContractFileCard.vue?vue&type=script&lang=js"
import style0 from "./ContractFileCard.vue?vue&type=style&index=0&id=254a725c&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "254a725c",
  null
  
)

export default component.exports